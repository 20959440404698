import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SelectSearch = _resolveComponent("SelectSearch")!

  return (_openBlock(), _createBlock(_component_SelectSearch, {
    onFocus: _ctx.fetchCity,
    onBlur: _ctx.onBlur,
    onFilter: _ctx.fetchCity,
    isLoading: _ctx.isLoadingCity,
    options: _ctx.optionCity,
    keyName: _ctx.keyName,
    keyValue: _ctx.keyValue,
    placeholder: _ctx.placeholder,
    modelValue: _ctx.modelValue,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:modelValue', $event))),
    onChange: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('change', $event))),
    isDisabled: _ctx.disabled
  }, null, 8, ["onFocus", "onBlur", "onFilter", "isLoading", "options", "keyName", "keyValue", "placeholder", "modelValue", "isDisabled"]))
}