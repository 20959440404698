
import { Vue, Options, prop } from "vue-class-component";
import debounce from "lodash/debounce";
import { LocationController } from "@/app/ui/controllers/LocationController";
import { CityData } from "@/domain/entities/Location";

class Props {
  modelValue = prop<any>({});
  placeholder = prop<string>({
    default: "Pilih kota"
  });
  status = prop<string>({
    default: ""
  });
  disabled = prop<boolean>({
    default: false
  });
  onlyCityCode = prop<boolean>({
    default: false
  });
  isError = prop<boolean>({
    default: false
  });
  keyName = prop<string>({
    default: "codeNameCity"
  });
  keyValue = prop<string>({
    default: "code"
  });
}

@Options({
  emits: ["update:modelValue", "update:isError", "change"],
  watch: {
    modelValue() {
      this.$emit("update:isError", !this.modelValue);
    }
  }
})
export default class SelectCity extends Vue.with(Props) {
  isLoadingCity = true;
  listCity: CityData[] = [];
  fetchCity = debounce(async (search: string) => {
    this.isLoadingCity = true;
    const responseCity = await LocationController.getCityList({
      search: search,
      status: this.status,
      page: 1,
      limit: 10
    });
    this.listCity = responseCity.cityData;
    this.isLoadingCity = false;
  }, 250);
  get optionCity(): CityData[] {
    return this.listCity;
  }
  onBlur() {
    this.isLoadingCity = true;
  }
}
